import { getFirstPaintData } from '@/api/firstPaint';
import { COL } from '@/def/col';
import { setBitMemory } from '@/shared/flag';

/**
 * =================================================
 *
 * Constants
 *
 * =================================================
 */

const LOAD_FIRST_PAINT_DATA = 'LoadFirstPaintData';
const SET_APP_DATA = 'SetAppData';
const SET_COL_FLAG = 'SetColFlag';

/**
 * =================================================
 *
 * State
 *
 * =================================================
 */
const state = () => ({
    oem: false,
    jzVersion: null,
    openDays: null,
    aid: null,
    cid: null,

    staffClose: false,
    manageClose: false,
    extId: null,
    lcid: null,
    colInfo: {},
    colId: -1,
    isUseNewUI: true,

    isDebug: false,
    uiMode: false,

    resRoot: '',
    resImageRoot: '',
    loadingPath: '',

    openThemeV3: true,
    colorFulThemeColor: '',
    themeColor: '',
    isOpenThemeColor: false,
    isRegularHeadStyle: false,

    templateFrameId: null,
    templateLayoutId: null,
    templatePresetIndex: null,
    pattern: null,
    _ver: null,
    _token: null,
    backTop: null,
    mallThemeColor: '',
    _templateColInfo: null,
    mobiBgMusic: null,
    _openMsgSearch: false,
    LS: {}, // 多语言配置
    templateDesignType: 0,
    silentLogin: false, // 微信静默登录判断
    allowedBackTop: false,
    mobiFooterClose: false,
    independentSetFooter: false,
    independentSetFooterHide: false,
    isJzCdnFlowLimit: false,
    isJzDownloadCdnFlowLimit: false,
    isOpenMResp: false,

    openMobiHtmlUrl: false,
    _setReqHttpOnlyCookie: false,

    detailPageHasBottomFixed: false,

    realNameAuth: {},
    openMultiPdInquiry: false,

    hosts: {},
});

/* =================================================
 *
 * Getters
 *
 * =================================================
 */
const getters = {
    colType: (state) => {
        return state.colInfo?.type;
    },
    isBUser: (state) => {
        return state.aid % 2 !== 0;
    },
    getColorFulThemeColor(state) {
        return state.colorFulThemeColor;
    },
    getMallThemeColor(state) {
        return state.mallThemeColor;
    },
    isSysCol: (state, getters) => {
        return getters.colType === state.colId && COL.SYS_INDEX !== getters.colType;
    },
    isDependentCol: (state) => {
        return state.colInfo?.independent ?? false;
    },
    pageTemplateOpen(state) {
        return state.openThemeV3;
    },
    hashRemoved(state) {
        return state.openMobiHtmlUrl && state._setReqHttpOnlyCookie;
    },
};

/**
 * =================================================
 *
 * actions
 *
 * =================================================
 */
const actions = {
    async loadFirstPaintData({ commit }, { colId, cusUrl }) {
        const res = await getFirstPaintData({ colId, cusUrl });
        if (res.success === true) {
            commit(LOAD_FIRST_PAINT_DATA, res.data.data);
            const _columnModuleHiddenList = res.data.data?._columnModuleHiddenList ?? [];
            commit('clearColumnHiddenModules', [], { root: true });
            commit('setHiddenModules', _columnModuleHiddenList, { root: true });
        }
        return res;
    },
    setData({ commit }, payload) {
        commit(SET_APP_DATA, payload);
    },
    setColFlag({ commit }, { bit, value }) {
        commit(SET_COL_FLAG, { bit, value });
    },
};

/**
 * =================================================
 *
 * mutations
 *
 * =================================================
 */
const mutations = {
    [LOAD_FIRST_PAINT_DATA](state, payload) {
        state.oem = payload._oem;
        state.jzVersion = payload.jzVersion;
        state.openDays = payload._openDays;
        state.aid = payload._aid;
        state.cid = payload._aid;

        state.staffClose = payload.staffClose;
        state.manageClose = payload.manageClose;
        state.extId = payload._extId;
        state.lcid = payload._lcid;
        state.colInfo = payload._colInfo;
        state.colId = payload._colInfo?.id ?? -1;

        state.isUseNewUI = true;
        state.isNewUser2 = payload.isNewUser2;

        state.isDebug = payload.isDebug;
        state.uiMode = payload._uiMode;

        state.resRoot = payload._resRoot;
        state.resImageRoot = payload._resImageRoot;
        state.loadingPath = payload._loadingPath;

        state.openThemeV3 = typeof payload._openThemeV3 === 'undefined' ? payload._isOpenThemeV3 : payload._openThemeV3;
        state.colorFulThemeColor = payload._colorFulThemeColor;
        state.themeColor = payload._themeColor;
        state.isOpenThemeColor = payload._isOpenThemeColor;
        state.isRegularHeadStyle = payload.isRegularHeadStyle;

        state.templateFrameId = payload._templateFrameId;
        state.templateLayoutId = payload._templateLayoutId;
        state.templatePresetIndex = payload._templatePresetIndex;
        state.pattern = payload._pattern;
        state._ver = payload._ver;
        state._token = payload._token;
        state.backTop = payload.backTop || payload?._other?.mobiBackTop || {};
        state.mobiBgMusic = payload?.mobiBgMusic || payload?._other?.mobiBgMusic || {};
        state._templateColInfo = payload._templateColInfo;

        state.designMode = payload.designMode || true;
        state._openMsgSearch = payload._openMsgSearch;

        if (payload.LS !== undefined) state.LS = payload.LS;

        state.templateDesignType = payload.templateDesignType;
        state.silentLogin = payload.silentLogin;
        if (payload._mobiFooterClose !== undefined) state.mobiFooterClose = payload._mobiFooterClose;
        if (payload.independentSetFooter !== undefined) state.independentSetFooter = payload.independentSetFooter;
        if (payload.independentSetFooterHide !== undefined)
            state.independentSetFooterHide = payload.independentSetFooterHide;

        state.isJzCdnFlowLimit = payload.isJzCdnFlowLimit;
        state.isJzDownloadCdnFlowLimit = payload.isJzDownloadCdnFlowLimit;
        state.isOpenMResp = payload.isOpenMResp;

        state.openMobiHtmlUrl = payload.openMobiHtmlUrl;
        state._setReqHttpOnlyCookie = payload._setReqHttpOnlyCookie;

        state.realNameAuth = payload?.realNameAuth ?? {};
        state.openMultiPdInquiry = payload.openMultiPdInquiry;

        state.memberName = payload.memberName;
        state.isLogin = payload._logined;

        state.hosts = payload.Hosts ?? {};
    },
    [SET_APP_DATA](state, payload) {
        Object.assign(state, payload);
    },
    updateLS(state, LS) {
        if (LS != null) {
            state.LS = LS;
        }
    },
    [SET_COL_FLAG](state, { bit, value }) {
        state.colInfo.flag = setBitMemory(state.colInfo.flag, bit, value);
    },
    updateAllowedBackTop(state, allowedBackTop) {
        state.allowedBackTop = allowedBackTop;
    },
    updateFullThemeColor(state, payload) {
        state.colorFulThemeColor = payload;
        state.themeColor = payload;
    },
    setPageBottomFixed(state, hasBottomFixed) {
        state.detailPageHasBottomFixed = hasBottomFixed;
    },
    setOpenMultiPdInquiry(state, openMultiPdInquiry) {
        state.openMultiPdInquiry = openMultiPdInquiry;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
