import './IntersectionObserver';
import { createApp } from './preview-main';
import { execPreviewScriptsOnDomReady, execPreviewScriptsOnLoaded } from './preview/index';
import { initData } from './first-paint';
import Vue from 'vue';
import { updateVistorPageState } from '@/features/router/updatePageData';
import { collectWebVitalsData } from '@jz/biz-shared';
import { encodeUrl } from '@/shared/util';

const { app, store } = createApp();

if (window.__INITIAL_STATE__) {
    // We initialize the store state with the data injected from the server
    window.LS && (window.__INITIAL_STATE__.app.LS = LS);
    store.replaceState(window.__INITIAL_STATE__);
    app.$mount('#preview', true);
    onLoadScripts();
} else {
    // CSR 逻辑
    if (window.renderData) {
        // commit
        window.renderData.LS = window.LS;
        renderByCSR(app, store, window.renderData).then(() => {
            onLoadScripts();
        });
    } else {
        app.$mount('#preview');
        onLoadScripts();
    }
}

jm(document).ready(function () {
    execPreviewScriptsOnDomReady();
});

jm(window).on('load.svg', function () {
    import('./preview-svg');
    collectWebVitalsData((data) => {
        jzRequest.post('/ajax/log_h.jsp', {
            data: {
                cmd: 'wafNotCk_logFdpForWebVitals',
                properties: {
                    ...data,
                    url: encodeUrl(window.location.href),
                },
            },
        });
    });
});

async function renderByCSR(app, store, data) {
    await initData(store, data);
    app.$mount('#preview');
}

function onLoadScripts() {
    Vue.nextTick(() => {
        updateVistorPageState();
        execPreviewScriptsOnLoaded();
    });
}
