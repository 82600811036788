import { isQQ } from '@/shared/util';

import { logDog } from '@/shared/log';
import { COL } from '@/def/col';
import { isWechat } from '@/shared/browser';
import { getcanvasTool } from './canvas';
import { getShareLinkList } from '@/api/linkSetting';
import { initWebPage } from '@/shared/misc/init-webpage';
import { isDevEnv, REQUEST_BASE_URL } from '@/shared/env';
import { Clue } from '@jz/biz-shared';
import { showPanel } from '@/shared/popup-panel';
import { initScaleImage } from '@/shared/photo/scale';
import { decodeHtml } from '@/shared/util';
export const videoQuit = function () {
    //jm(".productVideoMask").remove();
    document.getElementById('productVideoMask').style.display = 'none';
    document.getElementById('productVideo').load();
    jm('.productBullet').show();
    if (Fai.top._other.templateInfo.templateDesignType != 2) {
        jm('.productDetailHeader').show();
    }
    if (Fai.top._openThemeV3) {
        jm('.productDetailHeaderV3').show();
    }
};

export const sharePage = function (options) {
    if (!options) return;
    var shareList =
            typeof options.shareLinkList === 'string' ? jm.parseJSON(options.shareLinkList) : options.shareLinkList,
        fromOks = options.fromOks || false;

    var shareIconHtml = '',
        shareArr = [];

    if (VITE_APP_MODE !== 'visitor') {
        //管理态处理用户的链接
        var url = document.URL;
        // eslint-disable-next-line @jz/no-use-http-prefix
        url = url.replace('http://' + Fai.top._userHostName, '');
        // 兼容https
        // eslint-disable-next-line @jz/no-use-http-prefix
        url = url.replace('https://' + Fai.top._userHostName, '');
        if (url.indexOf('_safeMode=false&') > -1) {
            url = url.replace('_safeMode=false&', '');
        } else if (url.indexOf('?_safeMode=false') > -1) {
            url = url.replace('?_safeMode=false', '');
        } else if (url.indexOf('&_safeMode=false') > -1) {
            url = url.replace('&_safeMode=false', '');
        }
    }

    var isPromoter = false;
    var promoteInfo = null;
    if (Fai.top._isPromoter && Fai.top._isPromoterPd) {
        jm.ajax({
            url: '/ajax/promoter_h.jsp?cmd=getWafNotCk_getInfo&productId=' + options.productId,
            async: false,
            success(result) {
                result = jm.parseJSON(result);
                if (result.success) {
                    promoteInfo = result.info.config;
                    if (result.info.isPromoter && result.info.isPromotePd) {
                        isPromoter = true;
                    }
                }
            },
        });
    }
    console.log(shareList);
    for (let shareInfo in shareList) {
        let url = shareList[shareInfo].url,
            icon = shareList[shareInfo].icon;
        if (icon == 'baidu_tieba' && Fai.top._isFaiHost) {
            shareIconHtml +=
                "<a hidefocus='true' class='shareLink' href='javascript:;' onclick='Mobi.ing(\"" +
                LS.shareNot +
                "\");'><div class='shareNewIcon g_iconMiddle icon-" +
                icon +
                "'></div><div class='shareText icon-" +
                icon +
                "-text'></div></a>";
        } else if (icon == 'graphic_poster') {
            var graphicPosterFunc = options.graphicPosterFunc || '';
            shareIconHtml +=
                "<a id='graphicPosterItem' hidefocus='true' class='shareLink jzGrapicShare' href='javascript:;' onclick='" +
                graphicPosterFunc +
                "'><div class='shareNewIcon g_iconMiddle icon-" +
                icon +
                "'></div><div class='shareText icon-" +
                icon +
                "-text'></div></a>";
        } else if (icon == 'Weixin') {
            shareIconHtml +=
                "<a hidefocus='true' class='shareLink' id='shareLinkWeixin' href='javascript:;'><div class='shareNewIcon g_iconMiddle icon-" +
                icon +
                "'></div><div class='shareText icon-" +
                icon +
                "-text'></div></a>";
        } else if (icon == 'qq_share') {
            shareIconHtml +=
                "<a hidefocus='true' class='shareLink' id='shareLinkQQ' href='javascript:;'><div class='shareNewIcon g_iconMiddle icon-" +
                icon +
                "'></div><div class='shareText icon-" +
                icon +
                "-text'></div></a>";
        } else if (icon == 'copy_url') {
            shareIconHtml +=
                "<a hidefocus='true' class='shareLink' id='copy_url' data-clipboard-text='" +
                url +
                "' href='javascript:;' style='outline:none;'><div class='shareNewIcon g_iconMiddle icon-" +
                icon +
                "'></div><div class='shareText icon-" +
                icon +
                "-text'></div></a>";
        } else if (icon === 'Pinterest') {
            shareIconHtml += `
                <a hidefocus='true' class='shareLink' id='shareLinkPinterest' href='javascript:;' style='outline:none;'>
                    <div class="shareNewIcon g_iconMiddle Pinterest">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><title>Pinterest</title><circle cx="16" cy="16" r="14" transform="translate(-1.68 30.13) rotate(-83.63)" fill="#ee262a"/><path d="M13.69,24.82a.85.85,0,0,0,.16-.17,8.29,8.29,0,0,0,1-2.18c.2-.8.41-1.61.63-2.47a2.45,2.45,0,0,0,1.09.92,4,4,0,0,0,3.69-.39,5.11,5.11,0,0,0,2-2.34,7.51,7.51,0,0,0,.65-4.59A5.31,5.31,0,0,0,19.5,9.54a7.22,7.22,0,0,0-7.88,1.62,5.5,5.5,0,0,0-1.23,5.93,2.76,2.76,0,0,0,1.5,1.64c.32.14.48.07.57-.27,0-.07,0-.15,0-.21a1.23,1.23,0,0,0-.19-1.3,3,3,0,0,1-.39-2.42,4.56,4.56,0,0,1,4.55-3.88,5,5,0,0,1,1.92.3,3.28,3.28,0,0,1,2.22,2.92,6.87,6.87,0,0,1-.83,4.18A2.67,2.67,0,0,1,18,19.53,1.54,1.54,0,0,1,16,18.14,4.11,4.11,0,0,1,16.23,17c.2-.77.43-1.52.61-2.29a2.15,2.15,0,0,0,0-.76,1.29,1.29,0,0,0-2.09-.9,2.51,2.51,0,0,0-1,2A4.07,4.07,0,0,0,14,16.69a.7.7,0,0,1,0,.38c-.4,1.75-.82,3.49-1.2,5.23a10,10,0,0,0-.15,1.45c0,.19,0,.38,0,.61,0,0,0,.49.25.59A.84.84,0,0,0,13.69,24.82Z" fill="#fff"/></svg>
                    </div>
                    <div class='shareText pinterest-text'></div>
                </a>
            `;
        } else if (icon === 'Twitter') {
            shareIconHtml = `
                <a hidefocus='true' class='shareLink' id='shareLinkTwitter' href='${url}' target='_blank' style='outline:none;'>
                    <div class="shareNewIcon g_iconMiddle Twitter">
                        <svg><use xlink:href="#icon_x"></use></svg>
                    </div>
                    <div class='shareText icon-Twitter-text'></div>
                </a>
            `;
        } else {
            // 部分苹果手机可能有onclick的兼容问题，改为href实现跳转
            // shareIconHtml += "<a hidefocus='true' class='shareLink' href='javascript:;' onclick='window.open(\""+ url + "\")'><div class='shareNewIcon g_iconMiddle icon-"+ icon +"'></div><div class='shareText icon-"+ icon +"-text'></div></a>";
            // --bug=1070622 --user=陈奖 mobi一键分享至新浪微博提示url格式错误 https://www.tapd.cn/42954297/s/1424447
            shareIconHtml += `<a hidefocus='true' class='shareLink' href='${url}' target='_blank' rel='${
                icon == 'sina_weibo' ? 'noreferrer noopener' : ''
            }'>
                                            <div class='shareNewIcon g_iconMiddle icon-${icon}'></div>
                                            <div class='shareText icon-${icon}-text'></div>
                                        </a>`;
        }
        if ((parseInt(shareInfo) + 1) % 8 === 0) {
            shareArr.push("<div class='swipImgBox'>" + shareIconHtml + '</div>');
            shareIconHtml = '';
        }
    }

    let lanCodeClass = '';
    if (window.$store.state.app.lcid == 2052) {
        lanCodeClass = 'g_locale2052';
    }

    shareList = shareList || [];
    shareIconHtml && shareArr.push(`<div class='swipImgBox'>${shareIconHtml}</div>`);
    var showStyle = '';
    if (shareList.length <= 4) {
        showStyle = "style='height:5rem';";
    }
    var shareHtml =
        "<div class='shareListPanel' id='shareListPanel" +
        (fromOks ? '2' : '') +
        "'>" +
        "<div class='shareListPanelBg' id='shareListPanelBg" +
        (fromOks ? '2' : '') +
        "'></div>" +
        "<div class='shareListContent " +
        lanCodeClass +
        "'>";
    if (isPromoter) {
        let text = '店铺';
        shareHtml +=
            "<div class='shareTitle' style='color: #333; margin-top: .5rem;'>分享后可赚" +
            (promoteInfo.awardType == 0 ? promoteInfo.promoteAward + '%' : promoteInfo.promoteAwardMoney + '元') +
            '的推广奖励</div>';
        shareHtml += `<div class='promoterPdShare'>他人通过您的分享进${text}购买后，您可获得推广奖励，奖励金额将根据买家的实付金额进行计算。</div>`;
    } else {
        shareHtml += "<div class='shareTitle'>" + LS.shareIt + '</div>';
    }

    shareHtml +=
        "<div class='shareListShow' id='shareListShowSwipe" +
        (fromOks ? '2' : '') +
        "' " +
        showStyle +
        '>' +
        "<div class='shareListSwipe'>" +
        shareArr.join('') +
        '</div>';
    if (shareList.length > 8) {
        shareHtml +=
            '<div class="shareBullet">' +
            '<ul id="bullets" class="bullets">' +
            '<li class="on"></li>' +
            '<li></li>' +
            '</ul>' +
            '</div>';
    }

    return shareHtml;
};

export const showShare = async function (url) {
    let all_oks_LinkUrl = [];
    if (Fai.top.all_oks_LinkUrl) {
        all_oks_LinkUrl = Fai.top.all_oks_LinkUrl;
    } else {
        let result = await getShareLinkList();
        Fai.top.all_oks_LinkUrl = result.data.all_oks_LinkUrl;
        all_oks_LinkUrl = Fai.top.all_oks_LinkUrl;
    }

    var shareData = Fai.top._onlineServiceJson.oneKeySharing.shareLinkList;
    var tempDataArr = [];
    all_oks_LinkUrl.forEach(function (id) {
        if (shareData.indexOf(id.icon) != -1) {
            tempDataArr.push(id);
        }
    });
    var data = {
        shareLinkList: JSON.stringify(tempDataArr),
        fromOks: true,
    };

    var shareOption = {
        triggerId: 'oneKeySharing',
        pageBg: true,
        pageHtml: sharePage(data),
        direction: 'bottom',
        callback() {
            showCommSharePanel('shareListShowSwipe2', 'page', url);
        },
        closeWebPage: '#shareListPanelBg2',
    };
    if (Fai.top._oneKeySharingChanged) {
        shareOption.redo = true;
    }

    // 如果是图文海报的话，则redo
    if (url != null && url != '') {
        shareOption.redo = true;
    }

    showPanel(shareOption);
};

export const commShareTemplateData = function (type, url) {
    let result;
    type = type || 'page'; // 不传，表示常规页面调用
    let colInfo = Fai.top._colInfo; // 页面内容
    let productInfo = {};
    let location = Fai.top.location;
    // 产品展示时对数据进行处理
    if (type == 'product') {
        productInfo = shareDataGetProductInfo();
    }
    let templateMap = {
        photo: Fai.top._shareDataPhotoDetail,
        news: Fai.top._shareDataNewsDetail,
        product: productInfo,
        page: {
            title: colInfo.name,
            url: location.href,
            img: url || '',
        },
    };

    result = templateMap[type] || {};
    result['type'] = type; // 存储展示内容的类型

    // 文章详情、图册详情、产品详情 中设置了链接
    switch (colInfo.id) {
        case 4:
        case 28:
            result.type = 'news';
            result.title = Fai.top._shareDataNewsDetail.title + '';
            break; // 用字符串，数字类型渲染不出来
        case 5:
        case 20:
            productInfo = shareDataGetProductInfo();
            result.type = 'product';
            result.title = productInfo.title + '';
            result.price = productInfo.price;
            result.isHiddenPrice = Fai.top._shareDataProdectDetail.isHiddenPrice;
            break; // 产品分类详情
        case 24:
            result.type = 'photo';
            result.title = Fai.top._shareDataPhotoDetail.title + '';
            break; // 图册详情
        default:
            break;
    }

    return result;
};

/* 展示公共分享面板 图册详情、文章详情, 从产品展示那边分离开*/
export const showCommSharePanel = function (swipeId, type, url) {
    let jqmobi = jm;
    swipeId = swipeId || 'shareListShowSwipe';
    let swipe = document.getElementById(swipeId);
    if (!swipe) return;
    let canvasDataInfo = commShareTemplateData(type, url);

    let graphicPosterOption = {
        triggerId: 'graphicPosterItem',
        pageBg: true,
        pageHtml: createGraphicPosterPage(),
        direction: 'bottom',
        closeWebPage: '#graphicPosterPanelBg',
        redo: true,
        callback() {
            drawCommCanvasSharePicture(canvasDataInfo);
        },
    };

    initWebPage(graphicPosterOption);

    initScaleImage(swipe);

    jqmobi('#' + swipeId).swipehandle(swipe, {
        //auto: 3000,
        continuous: true,
        bulletsClick: true,
        callback(pos) {
            var liLen = bullets.length;
            while (liLen--) {
                bullets[liLen].className = ' ';
            }
            if (!bullets[pos]) {
                bullets[pos - bullets.length].className = 'on';
            } else {
                bullets[pos].className = 'on';
            }
            //兼容产品视频
            var mobiProductVideoDialog = jm('.mobiProductVideoDialog');
            if (mobiProductVideoDialog.length == 1) {
                //退出产品视频
                videoQuit();
            }
        },
    });

    jm('.shareListSwipe').bind('click', function (e) {
        logDog(201083, 1);
        if (
            !(VITE_APP_MODE !== 'visitor') &&
            type === 'news' &&
            [COL.SYS_NEWS_DETAIL, COL.SYS_NEWS_NEW_DETAIL, COL.SYS_NEWS_DETAIL_V3].includes(Fai.top._colInfo.id) &&
            jm(e.target).hasClass('shareNewIcon')
        ) {
            // 分享文章操作行为线索上报
            Clue?.logClueShareNewsAction();
        }
    });

    if (!swipe.querySelector('.bullets')) return;
    let bullets = swipe.querySelector('.bullets').getElementsByTagName('li');
};

export const bindTransitionEnd = function (tranId, callback) {
    if (!tranId) return;

    var tranDom = tranId;

    if (Object.prototype.toString.call(tranDom) === '[object String]') {
        tranDom = jm(tranId);
    }

    var transitionEnd = getTransitionEndEventName();

    tranDom.unbind(transitionEnd);
    tranDom.bind(transitionEnd, callback);
};

export const initShare = function (tmp, tmp1, tmp2) {
    var shareId = tmp;
    jm('#' + shareId)
        .unbind('click')
        .bind('click', function () {
            // 分享文章操作行为线索上报
            !tmp.includes('footer') && Clue?.logClueShareNewsAction();
            if (!isWechat()) {
                let html = [];
                html.push("<div class='shareCover' style='background-color:white;opacity:1;'>");
                html.push(
                    "		<img class='shareCoverIcon shareCoverContent' src='" +
                        Fai.top._resRoot +
                        "/image/v2/shareIcon.png?v=201703240921'/>"
                );
                html.push("		<div class='shareCoverTip shareCoverContent'>请使用浏览器的分享功能分享到微信</div>");
                html.push("		<div class='shareCoverBtn shareCoverContent'>知道了</div>");
                html.push('</div>');
                if (jm('.shareCover').length == 0) {
                    jm('#g_web').append(html.join(''));
                    jm('.shareCoverBtn').bind('click', function () {
                        jm('.shareCoverBtn').unbind();
                        jm('.shareCover').remove();
                    });
                }
            } else {
                let html = [];
                html.push("<div class='shareCover'>");
                html.push(
                    "		<img class='shareCoverImg' src='" + Fai.top._resRoot + "/image/v2/shareTip.png?v=201703240921'/>"
                );
                html.push('</div>');
                if (jm('.shareCover').length == 0) {
                    jm('#g_web').append(html.join(''));
                    jm('.shareCover').bind('click', function () {
                        jm('.shareCover').unbind().remove();
                    });
                }
            }
        });
    if (tmp1 == null) {
        return;
    }
    shareId = tmp1;
    jm('#' + shareId)
        .unbind('click')
        .bind('click', function () {
            // 分享文章操作行为线索上报
            !tmp1.includes('footer') && Clue.logClueShareNewsAction();
            window.open(
                '//service.weibo.com/share/share.php?title= &url=http%3A%2F%2Fm.jack.iii.cc.jack.dev.cc%2Fpd.jsp%3Fpid%3D123%26_sc%3D2&pic='
            );
        });

    shareId = tmp2;
    jm('#' + shareId)
        .unbind('click')
        .bind('click', function () {
            // 分享文章操作行为线索上报
            !tmp2.includes('footer') && Clue.logClueShareNewsAction();
            if (!isQQ()) {
                let html = [];
                html.push("<div class='shareCover' style='background-color:white;opacity:1;'>");
                html.push(
                    "		<img class='shareCoverIcon shareCoverContent' src='" +
                        Fai.top._resRoot +
                        "/image/v2/shareIcon.png?v=201703240921'/>"
                );
                html.push("		<div class='shareCoverTip shareCoverContent'>请使用浏览器的分享功能分享到QQ</div>");
                html.push("		<div class='shareCoverBtn shareCoverContent'>知道了</div>");
                html.push('</div>');
                if (jm('.shareCover').length == 0) {
                    jm('#g_web').append(html.join(''));
                    jm('.shareCoverBtn').bind('click', function () {
                        jm('.shareCoverBtn').unbind();
                        jm('.shareCover').remove();
                    });
                }
            } else {
                let html = [];
                html.push("<div class='shareCover'>");
                html.push(
                    "		<img class='shareCoverImg' src='" +
                        Fai.top._resRoot +
                        "/image/v2/shareTip02.png?v=201703240921' style=''/>"
                );
                html.push('</div>');
                if (jm('.shareCover').length == 0) {
                    jm('#g_web').append(html.join(''));
                    jm('.shareCover').bind('click', function () {
                        jm('.shareCover').unbind().remove();
                    });
                }
            }
        });
};

//获取不同浏览器下transitionEnd事件的前缀
const getTransitionEndEventName = function () {
    var i,
        undefined,
        el = document.createElement('div'),
        transitions = {
            transition: 'transitionend',
            OTransition: 'otransitionend', // oTransitionEnd in very old Opera
            MozTransition: 'transitionend',
            WebkitTransition: 'webkitTransitionEnd',
        };

    for (i in transitions) {
        if (Object.hasOwnProperty.call(transitions, i) && el.style[i] !== undefined) {
            return transitions[i];
        }
    }
};

const shareDataGetProductInfo = function () {
    let productInfo = {};
    let shareProductDetail = Fai.top._shareDataProdectDetail || {};
    let shareProductInfo = shareProductDetail.productInfo || {};
    let jsShareInfoStr = shareProductDetail.jsShareInfo || '{}';
    let jsShareInfo = JSON.parse(jsShareInfoStr);

    let location = Fai.top.location;

    productInfo['title'] = shareProductInfo.name || '';
    productInfo['img'] = shareProductInfo.picPath || '';
    productInfo['url'] = location.href || '';
    productInfo['price'] = jsShareInfo.sharePrices.firstPrice || '0.00';

    return productInfo;
};

// 链接弹窗设置 生成分享海报调用
export const showCreateShareGrapic = function (options) {
    let pageHtml = createGraphicPosterPage();
    let pageId = 'graphicPosterItem';
    let JMWebPagePanel = jm('#webPagePanel' + pageId);
    let pdThemev3 = '',
        JMTagetId;
    let closeNode = '#webPagePanel' + pageId + ' .webPagePanelBg';
    let colInfo = Fai.top._colInfo; // 页面内容
    let location = Fai.top.location;
    let grapicInfo = {
        title: colInfo.name,
        url: location.href,
        img: options.picUrl || '',
    };
    let productInfo = {};
    // 文章详情、图册详情、产品详情
    switch (colInfo.id) {
        case 4:
        case 28:
            grapicInfo.title = Fai.top._shareDataNewsDetail.title + '';
            grapicInfo.type = 'news';
            break; // 用字符串，数字类型渲染不出来
        case 5:
        case 20:
            productInfo = shareDataGetProductInfo();
            grapicInfo.title = productInfo.title + '';
            grapicInfo.type = 'product';
            grapicInfo.price = productInfo.price;
            grapicInfo.isHiddenPrice = Fai.top._shareDataProdectDetail.isHiddenPrice;
            break; // 产品分类详情
        case 24:
            grapicInfo.title = Fai.top._shareDataPhotoDetail.title + '';
            grapicInfo.type = 'photo';
            break; // 图册详情
        default:
            break;
    }

    if (JMWebPagePanel.length !== 0) {
        JMWebPagePanel.remove();
    }

    jm('#g_body').css('overflow-y', 'hidden');
    if (Fai.top._colInfo.id == 20 && Fai.top._openThemeV3) {
        pdThemev3 = ' pdThemev3';
    }

    let webPagePanelBg = "<div class='webPagePanelBg' style='z-index:999'></div>";
    jm('#g_web').append(
        "<div id='webPagePanel" +
            pageId +
            "' class='webPagePanel" +
            pdThemev3 +
            "' style='z-index:1000'>" +
            webPagePanelBg +
            '</div>'
    );
    JMWebPagePanel = jm('#webPagePanel' + pageId);

    if (pageHtml) {
        JMWebPagePanel.append(pageHtml);
        JMTagetId = jm(pageHtml);
        //jm很奇怪，jm("<div id='id'></div>")这样设置class不行，
        JMTagetId = jm('#' + JMTagetId[0].id);
        JMTagetId.addClass('webPagePanelBottom');
        JMTagetId.addClass('webPagePanel');
        JMWebPagePanel.show();
        JMTagetId.show();

        setTimeout(function () {
            JMTagetId.addClass('webPagePanelShow');
        });
        JMTagetId.unbind('touchmove').bind(
            'touchmove',
            function (e) {
                e.preventDefault();
            },
            false
        );
        jm(closeNode)
            .unbind('touchmove')
            .bind(
                'touchmove',
                function (e) {
                    e.preventDefault();
                },
                false
            );
        //绑定一个click事件，用于适应pc端点击事件
        jm(closeNode)
            .unbind('click')
            .bind('click', function () {
                JMTagetId.removeClass('webPagePanelShow');
                jm('#g_body').css({ overflow: '', height: '', top: '' });
                jm('#g_body').css('overflow-y', '');
                jm('html').css({ overflow: '' });
                jm('#g_web').removeClass('gwNoScroll');
                jm('#webPagePanel' + pageId).css('z-index', '-1');
                jm('#webPagePanel' + pageId).css('display', 'none'); // 关闭黑色背景，不然黑色背景会停顿，再消失
                jm('#webPagePanel' + pageId).remove();
                jm(closeNode).unbind('touchmove'); // 解除事件
                JMTagetId.unbind('touchmove');
                //返回时关闭软键盘，避免键盘对页面排版的影响
                if (pageId == 'userCommentId' || pageId == 'postComment') {
                    jm('input,textarea').each(function (k, v) {
                        v.blur();
                    });
                }
                return false;
            });

        bindTransitionEnd(JMTagetId, function () {
            if (!JMTagetId.hasClass('webPagePanelShow')) {
                JMWebPagePanel.hide();
            }
        });
    }
    let height = jm('#g_web').height();
    JMWebPagePanel.css('height', height + 'px');
    JMWebPagePanel.css('z-index', '10000');

    drawCommCanvasSharePicture(grapicInfo);
};

export const createGraphicPosterPage = function () {
    var baseFontSize = jm('html').css('font-size');
    baseFontSize = Number(baseFontSize.substring(0, baseFontSize.indexOf('p')));
    var canvasWidth = baseFontSize * 12;
    var canvasHeight = baseFontSize * 15.7;

    var shareHtml = '';
    shareHtml +=
        "<div class='page_graphicPoster' id='graphicPosterShowSwipe'>" +
        "<div id='graphicPosterPanelBg'></div>" +
        "<div class='myCanvasContain'>" +
        "<canvas id='myCanvas' class='myCanvas' width='" +
        canvasWidth +
        "px' height='" +
        canvasHeight +
        "px'>" +
        '</div>' +
        "<div class='myCanvasImgContain'>" +
        "<canvas id='myCanvasImg' width='" +
        canvasWidth +
        "px' height='" +
        canvasHeight +
        "px'>" +
        '</div>' +
        "<div id='themColor' class='g_color g_main_color_v3'></div>" +
        // "<div class='promptTextContain'>"+
        //     "<p>长按保存海报</p>"+
        // "</div>"+
        '</div>';
    return shareHtml;
};

/*
    展示生成的 canvas
    options: {
        img：canvas首图图片链接,
        title: 标题,
        url: 链接，用于生成二维码,
        showPrice: false,   //
        callback: 回调方法
    },

*/
export const drawCommCanvasSharePicture = function (options) {
    let canvas = document.getElementById('myCanvas');
    if (canvas == null) return;

    let canvasTool = getcanvasTool(canvas);
    if (!canvasTool) return;
    let ctx = canvasTool.ctx;
    let baseFontSize = canvasTool.baseFontSize;
    let canvasShowWidth = baseFontSize * 11; // 图片宽度 440 -> 11.25rem
    let canvasShowHeight = baseFontSize * 13.85; // 未含标题的高度 554 -> 13.85rem
    let titleHeight = 0.8 * baseFontSize; // 文字高度: 22px -> 0.55rem
    let maxTitleLength = 10 * baseFontSize; // 标题文字最大宽度
    let price = 0; // 产品展示特有的价格

    let token = jm('#_TOKEN').attr('value');

    let tempFontInfo = {
        font: 0.55 * baseFontSize + 'px PingFang-SC-Medium',
    };

    if (options.type == 'product') {
        if (options.isHiddenPrice === false) {
            price = (0.65 + 0.5) * baseFontSize; // line-height: 32px, height: 20px
            canvasShowHeight += price; // 会多出20px空隙
        }
    }

    if (options.img == '') {
        options.img = `${window._resRoot}/image/no-pic.jpg`; // 默认图
    }

    // 获取标题高度
    let titleLine = getTitleLine(ctx, options.title, maxTitleLength, tempFontInfo);
    titleHeight = titleLine * titleHeight;
    canvasShowHeight += titleHeight - 0.275 * baseFontSize; // 添加标题的高度，减掉最后一行标题之间的间隙，11px ->

    canvas.width = canvasShowWidth;
    canvas.height = canvasShowHeight;

    // 首图大小
    let headDefaultSize = 10 * baseFontSize; // 首图宽高：400px -> 10rem
    let headImgWidth = headDefaultSize,
        headImgHeight = headDefaultSize;

    let naturalHeight = headDefaultSize,
        naturalWidth = headDefaultSize;

    let loadPdImg = false,
        loadQrCode = false;

    let headImg = new Image();
    let host = isDevEnv ? REQUEST_BASE_URL : `${document.location.protocol}//${document.location.host}`;
    headImg.src = `${host}/api/manage/linkSetting/mobiImgTransfer?_TOKEN=${token}&imgUrl=${jm.encodeUrl(
        options.img
    )}&isZip=true`;

    // headImg.src = options.img;

    headImg.onload = function () {
        naturalHeight = this.naturalHeight;
        naturalWidth = this.naturalWidth;
        let scale = 1;
        if (naturalWidth >= naturalHeight) {
            headImgHeight = headDefaultSize;
            scale = headImgHeight / naturalHeight;
            headImgWidth = scale * naturalWidth;
            // headImgOffset = headDefaultSize - headImgHeight;
        } else {
            headImgWidth = headDefaultSize;
            scale = headImgWidth / naturalWidth;
            headImgHeight = scale * naturalHeight;
        }

        loadPdImg = true;
        if (isLoaded()) {
            doCanvas(ctx);
        }
    };

    let qrCode = new Image();
    qrCode.src = `${host}/api/manage/linkSetting/printUrlQRCode?size=166&siteUrl=${jm.encodeUrl(options.url)}`;

    qrCode.onload = function () {
        loadQrCode = true;
        if (isLoaded()) {
            doCanvas(ctx);
        }
    };

    function isLoaded() {
        return loadPdImg && loadQrCode;
    }

    function doCanvas() {
        let fontSize = jm('html').css('fontSize').split('px');
        let bottomFontHeight = 2; // 长按保存图片（包括文字）距离图片的距离 2rem
        let backgroundHeight = 13.85 + 0.8 * titleLine + bottomFontHeight + price / baseFontSize + 'rem',
            backgroundHeightPx =
                (13.85 + 0.8 * titleLine + bottomFontHeight + price / baseFontSize) * parseFloat(fontSize),
            drawCanvasWidth = canvasShowWidth,
            drawCanvasHeight = canvasShowHeight;
        jm('#myCanvas').attr('height', drawCanvasHeight);
        jm('#myCanvas').attr('width', drawCanvasWidth);
        jm('#graphicPosterShowSwipe').css('height', backgroundHeight);
        let rect = canvasTool.Rect(0, 0, drawCanvasWidth, drawCanvasHeight);
        canvasTool.drawRoundedRect(rect, 0.35 * baseFontSize, '#F7F7F7', false); // 画圆角 14px -> 0.35

        drawHeadImg(); // 画首图
        if (options.type == 'product') {
            if (options.isHiddenPrice === false) {
                drawPrice();
            }
        }
        drawTitle();
        drawQrCode(); // 画二维码
        drawTips(); // 画备注
        if (!document.getElementById('myCanvas')) {
            return;
        }
        // 输出保存图片
        let downloadLink = document.getElementById('myCanvas').toDataURL('image/png');
        let posterImg = new Image();
        posterImg.src = downloadLink;
        posterImg.setAttribute('class', 'actualPoster');
        posterImg.setAttribute(
            'style',
            'width:11rem;height:' + drawCanvasHeight / baseFontSize + 'rem;user-select: none;-webkit-user-select:none;'
        ); //控制输出图片宽度比例, user-select: none; 控制图片不让选中
        jm('#myCanvasImg').remove();
        jm('.myCanvasContain').empty().append(posterImg);
        jm('.myCanvasContain').append(
            '<div style="margin-top: 1rem; text-align:center; color: #fff; font-size: 0.65rem;">' +
                LS.posterButtonTips +
                '</div>'
        );
        // 覆盖兼容原海报窗口
        let panelHeight = jm('#webPagePanelgraphicPosterItem')[0].offsetHeight;

        jm('#graphicPosterShowSwipe')
            .css('width', canvasShowWidth / baseFontSize + 'rem')
            .css('left', (16 - canvasShowWidth / baseFontSize) / 2 + 'rem')
            .css('top', (panelHeight - backgroundHeightPx) / 2 + 'px');
        jm('.myCanvas').css('opacity', 1);
        if (!(VITE_APP_MODE !== 'visitor')) {
            logDog(201187, 2);
        }
        // jm(".actualPoster").css("height", canvasHeight);
    }

    function drawHeadImg() {
        // 画首图
        // 640px 设计稿
        // 设计稿 padding 值为 20, 20/40 = 0.5rem;
        // 设计稿下图片的宽高：400px, 400/40 = 10rem;
        let paddingXY = (20 / 640) * 16 * baseFontSize;
        // let rect = Rect(paddingXY, paddingXY, headDefaultSize, headDefaultSize);
        // canvasTool.drawRoundedRect(rect, 0, "#D3D3D3", true);
        // ctx.drawImage(headImg, paddingXY + (headDefaultSize - headImgWidth)/2, paddingXY + (headDefaultSize - headImgHeight)/2, headImgWidth, headImgHeight);
        // ctx.drawImage(headImg, paddingXY, paddingXY, headDefaultSize, headDefaultSize);
        // ctx.drawImage(headImg, paddingXY, paddingXY, headDefaultSize, headImgHeight);
        let sx = (((headImgWidth - headDefaultSize) / 2) * naturalWidth) / headImgWidth;
        let sy = (((headImgHeight - headDefaultSize) / 2) * naturalHeight) / headImgHeight;
        let sh = (headDefaultSize * naturalHeight) / headImgHeight;
        let sw = (headDefaultSize * naturalWidth) / headImgWidth;
        ctx.drawImage(headImg, sx, sy, sw, sh, paddingXY, paddingXY, headDefaultSize, headDefaultSize);
        drawRoundedRectNeedClipEnd();
    }

    function drawQrCode() {
        let paddingX = 0.5 * baseFontSize;
        let paddingY = titleHeight - 0.255 * baseFontSize + 10.875 * baseFontSize + price + 0.5 * baseFontSize;
        let qrCodeWidth = 1.875 * baseFontSize; //  二维码宽度 75px
        let qrCodeHeight = 1.875 * baseFontSize;
        ctx.drawImage(qrCode, paddingX, paddingY, qrCodeWidth, qrCodeHeight);
        drawRoundedRectNeedClipEnd();
    }

    function drawTips() {
        ctx.save();
        // 屏幕的设备像素比
        // 浏览器在渲染canvas之前存储画布信息的像素比
        // canvas的实际渲染倍率
        let text = LS.posterTips; // LS.posterTips
        let paddingX = 0.5 * baseFontSize + 1.875 * baseFontSize + 0.45 * baseFontSize;
        let paddingY = titleHeight - 0.255 * baseFontSize + 10.875 * baseFontSize + (0.5 + 1.1) * baseFontSize + price;
        ctx.font = 0.5 * baseFontSize + 'px SourceHanSansCN-Regular';
        ctx.fillStyle = '#999';
        ctx.fillText(text, paddingX, paddingY);
        ctx.restore();
    }

    function drawRoundedRectNeedClipEnd() {
        //使用clip切割时最后调用此方法用来结束drawRoundedRect的属性
        ctx.closePath();
        ctx.restore();
    }

    function getTitleLine(context, title, maxWidth, fontInfo) {
        let twoMaxWidth = 2 * maxWidth;
        context.font = fontInfo.font;
        let measureWidth = context.measureText(title).width;
        if (measureWidth <= maxWidth) {
            return 1;
        } else if (measureWidth > maxWidth && measureWidth <= twoMaxWidth) {
            return 2;
        } else {
            return 3;
        }
    }

    function drawPrice() {
        let fontInfo = {
            font: 0.65 * baseFontSize + 'px PingFang-SC-Medium',
            style: Fai.top._themeColor || '#FD6F28',
            align: 'left',
            baseLine: 'top',
        };

        let placeInfo = {
            maxWidth: 10 * baseFontSize,
            textX: 0.5 * baseFontSize,
            textY: 10.875 * baseFontSize,
            offset: 0.8 * baseFontSize,
        };
        // 价格单位小，整数数字大，小数数字小，我太难了
        if (Fai.top._openThemeV3) {
            let price = options.price.split('.');

            let unitWidth = measureTextWidth(
                ctx,
                0.5 * baseFontSize + 'px PingFang-SC-Medium',
                Fai.top.choiceCurrencyVal
            );
            let integerWidth = measureTextWidth(ctx, 0.65 * baseFontSize + 'px PingFang-SC-Medium', price[0] + '.');
            let smallTextY = 10.875 * baseFontSize + 0.15 * baseFontSize;
            let normalTextY = 10.875 * baseFontSize;

            fontInfo.font = 0.5 * baseFontSize + 'px PingFang-SC-Medium';
            placeInfo.textY = smallTextY;
            canvasTool.drawText(Fai.top.choiceCurrencyVal, fontInfo, placeInfo, '...', 1); // 画单位

            fontInfo.font = 0.65 * baseFontSize + 'px PingFang-SC-Medium';
            placeInfo.textX = placeInfo.textX + unitWidth;
            placeInfo.textY = normalTextY;
            canvasTool.drawText(price[0] + '.', fontInfo, placeInfo, '...', 1); // 画整数部分

            fontInfo.font = 0.5 * baseFontSize + 'px PingFang-SC-Medium';
            placeInfo.textX = placeInfo.textX + integerWidth;
            placeInfo.textY = smallTextY;
            canvasTool.drawText(price[1], fontInfo, placeInfo, '...', 1); // 画小数部分
        } else {
            let price = Fai.top.choiceCurrencyVal + options.price;
            canvasTool.drawText(price, fontInfo, placeInfo, '...', 1); // 画小数部分
        }
    }

    function drawTitle() {
        // 产品展示的话要加上价格的距离
        let textY =
            options.type == 'product'
                ? options.isHiddenPrice
                    ? 10.875 * baseFontSize
                    : 10.75 * baseFontSize + price
                : 10.875 * baseFontSize;

        let fontInfo = {
            font: 0.55 * baseFontSize + 'px PingFang-SC-Medium',
            style: '#333333',
            align: 'left',
            baseLine: 'top',
        };

        let placeInfo = {
            maxWidth: 10 * baseFontSize,
            textX: 0.5 * baseFontSize,
            textY,
            offset: 0.8 * baseFontSize,
        };
        canvasTool.drawText(decodeHtml(options.title), fontInfo, placeInfo, '...', 3);
    }

    function measureTextWidth(context, font, text) {
        context.font = font;
        return context.measureText(text).width;
    }
};

// 由dom节点提取图片信息
export const getPictureSlidesImgs = function (selector) {
    let images = [];
    let realIndex = 0;
    jm(selector).forEach(function (el) {
        var openLink = false;
        var $el = jm(el);
        if ($el.parent('video').length > 0) {
            return;
        }
        var closeATag = $el.closest('a');
        if (
            closeATag.length > 0 &&
            closeATag.attr('href') !== '' &&
            !closeATag.attr('href').startsWith('javascript:void(0)')
        ) {
            openLink = true;
        }

        let imageId = 'photoswipe_' + realIndex;
        images.push({
            src: $el.attr('src-original') || el.src,
            width: $el.attr('vwidth') || el.naturalWidth,
            height: $el.attr('vheight') || el.naturalHeight,
            title: '',
            description: '',
            detail: false,
            id: imageId,
            openLink,
        });

        el.setAttribute('data-picId', imageId);
        realIndex++;
    });
    return images;
};
