<template>
    <div v-show="showHeader" id="webHeader_v3" :class="headerClass" :styleid="headStyleId">
        <div id="web_header_fixed_box" class="fixedBox J_headerFixBox">
            <div id="headBg_v3" class="headBg_v3 g_main_bgColor_v3">
                <template v-if="!isRegularStyle || manageMode">
                    <div class="bg_content">
                        <div class="bg_content_color_box g_main_bgColor_v3"></div>
                        <div class="bg_content_white_box"></div>
                    </div>
                </template>
                <div id="header_v3" class="header_v3" :style="headOpacity">
                    <template v-if="headStyleId != 2 || manageMode">
                        <div id="headIcon_v3" class="headIcon_v3" pw="160" ph="160">
                            <a
                                v-bind="{
                                    href: logoJumpUrl,
                                    click: logoOnclickStr,
                                }"
                            >
                                <div class="f-logo"></div>
                            </a>
                        </div>
                    </template>
                    <template v-if="headStyleId > 2 || manageMode">
                        <div id="headTitle_v3" :class="headTitleClass">
                            <h1 id="headTitleV3H1" class="headTitleV3H1 headTitleV3H1TextAlign" :title="hoverTips">
                                <a
                                    v-bind="{
                                        href: titleUrl,
                                        click: titleOnclickStr,
                                    }"
                                    v-text="titleContent"
                                >
                                </a>
                                <template v-if="manageMode">
                                    <input
                                        id="headTitleInputV3"
                                        autocomplete="off"
                                        maxlength="50"
                                        autofocus="autofocus"
                                    /><span class="headTitleEditPen" title="点击修改网站标题"></span>
                                </template>
                            </h1>
                        </div>
                    </template>
                    <template
                        v-if="
                            headStyleId == 1 ||
                            headStyleId == 2 ||
                            (headStyleId == 5 && hideSearchBox == 0) ||
                            manageMode
                        "
                    >
                        <div id="searchBox" :class="searchBoxClass">
                            <template v-if="showDropMenu && dropMenuList.length > 0">
                                <div class="jsb-showRange-block">
                                    <div class="title-top-defaultTitle sb-searchRange">{{ otherTitle }}</div>
                                    <div class="arrow-svgDiv">
                                        <svg class="sb-icon-arrow">
                                            <use fill="#808080" xlink:href="#jzm-vicon_arrow_panel"></use>
                                        </svg>
                                    </div>
                                </div>
                                <i class="sb-f_icon_conter f_icon_conter faisco-icons-S000290 g_main_color_v3"></i>
                                <input id="topSearch" class="topSearch all-top-search" :placeholder="searchTips" />
                            </template>
                            <template v-else>
                                <div class="jsb-showRange-block" style="display: none">
                                    <div class="title-top-defaultTitle sb-searchRange">
                                        {{ otherTitle }}
                                    </div>
                                    <div class="arrow-svgDiv">
                                        <svg class="sb-icon-arrow">
                                            <use fill="#808080" xlink:href="#jzm-vicon_arrow_panel"></use>
                                        </svg>
                                    </div>
                                </div>
                                <input id="topSearch" class="topSearch" :placeholder="searchTips" /><i
                                    :class="topSearchIconClass"
                                ></i>
                            </template>
                        </div>
                    </template>

                    <!-- 样式 1，2，3，4 功能项 -->
                    <template v-if="headStyleId < 5 || isInnerPage || manageMode">
                        <div id="functional_group" :class="functionGroupClass">
                            <div class="contentBox">
                                <template v-if="iconType == 0 || manageMode">
                                    <div :class="loginClass">
                                        <a :href="profileUrl"><i class="faisco-icons-S000288"></i></a>
                                    </div>
                                </template>
                                <template v-if="(iconType == 1 && mallOpen) || manageMode">
                                    <div :class="showCarV3Class">
                                        <a :href="mcartUrl"><i class="faisco-icons-S000292"></i></a>
                                    </div>
                                </template>
                                <template v-if="iconType == 2 || manageMode">
                                    <div :class="mallNavClass">
                                        <a :href="productGroupBtnHref"><i class="faisco-icons-S000291"></i></a>
                                    </div>
                                </template>
                                <template v-if="iconType == 3 || isInnerPage || manageMode">
                                    <div :class="headerSearchIconClass">
                                        <a class="topSearch"
                                            ><i class="faisco-icons-S000290"></i><span class="iconTitle">搜索</span></a
                                        >
                                    </div>
                                </template>
                                <template v-if="openLanInfoList.length > 1 && (lanSwitch == 1 || manageMode)">
                                    <div :class="headLanSwitchIconClass">
                                        <div id="languaSwitch" class="languaSwitch">
                                            <i class="faisco-icons-S000289"></i>
                                        </div>
                                        <div id="languageSelect" class="languageSelect">
                                            <div class="arrow"></div>
                                            <div class="lanContainer">
                                                <div
                                                    v-for="lan in openLanInfoList"
                                                    :key="lan.id"
                                                    class="lanItem"
                                                    :class="{
                                                        [`lanCode${lan.lcid}`]: true,
                                                    }"
                                                    :value="lan.url"
                                                    :lanCode="createLanUrl(lan.lanCode)"
                                                    :currentLan="lan.lcid === lcid ? lan.lcid : void 0"
                                                >
                                                    <span
                                                        class="lanTitle"
                                                        :class="{
                                                            [`title<${lan.lcid}>`]: true,
                                                        }"
                                                        >{{ lan.name }}</span
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="isInnerPage || manageMode">
                                    <div class="innerPageItem">
                                        <a :href="profileUrl" class="iconTitle">
                                            {{ col_member_center }}
                                        </a>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </template>

                    <!-- 样式5，6，7 功能项 -->
                    <template v-if="headStyleId > 4 || manageMode">
                        <div id="functional_group2" class="functional_group2">
                            <!-- 全部商品 -->
                            <template v-if="topLabel === 0 || manageMode">
                                <a class="head_allItem funItem" :class="{ hidden: topLabel === 1 }" :href="prUrl">
                                    <span class="icon-allItem"></span>
                                    <span class="header_icon">{{ allProductNum }}</span>
                                    <span class="itemText">{{ mobi_allGoods }}</span>
                                </a>
                            </template>
                            <!-- 会员中心 -->
                            <a class="head_member funItem" :href="profileUrl">
                                <span class="header_icon icon-item"></span>
                                <span class="itemText">{{ col_member_center }}></span>
                            </a>
                            <!-- 我的订单，需要开启商城功能才会自动的开启 -->
                            <template v-if="mallOpen || manageMode">
                                <a class="head_myOrder funItem" :class="{ hidden: !mallOpen }" :href="mlistUrl">
                                    <span class="header_icon icon-item"></span>
                                    <span class="itemText">{{ member_center_order }}</span>
                                </a>
                            </template>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// created form web/mobi/inc/mobiThemeV3Head.jsp.inc
import { COL, COL_DEF_FLAG } from '@/def/col';
import { mapState, mapGetters, mapActions } from 'vuex';
import { bindTopSearch } from './search';
import { getPreviewObject } from '@/shared/getPreviewObject';
import { LocaterDomService } from '@/system/locater/locaterDomService';
import { setTitleIsEditable } from './title';
import { logoV3StyleAdjust } from './logo';
import { headBoxFixedV3 } from './header';
import { languageCtrlV3 } from '../../language';
import { checkBit } from '@/shared/fai';

const showHeaderCols = [
    COL.SYS_PRODUCT_RESULT,
    COL.SYS_NEWS_RESULT,
    COL.SYS_MSG_BOARD,
    COL.SYS_SEARCH_RESULT,
    COL.SYS_PRODUCT_SEARCH_RESULT,
    COL.SYS_PHOTOGROUP_RESULT,
    COL.SYS_CUSTOM_SEARCH_RESULT,
    COL.SYS_PHOTOGROUP_DETAIL,
    COL.SYS_NEWS_DETAIL_V3,
    COL.SYS_COMMUNITY_GROUP_BUYER_ACTIV_LIST,
];

const isNotRegularStyleOrManageMode = (isRegularStyle) => VITE_APP_MODE !== 'visitor' || !isRegularStyle;

const showHeaderColsByHandler = {
    [COL.SYS_PRODUCT_NEW_DETAIL]: isNotRegularStyleOrManageMode,
    [COL.SYS_MALL_CART]: isNotRegularStyleOrManageMode,
    [COL.SYS_MALL_ORDER_SETTLE]: isNotRegularStyleOrManageMode,
    [COL.SYS_MALL_ORDER_LIST]: isNotRegularStyleOrManageMode,
    [COL.SYS_MALL_ORDER_DETAIL]: isNotRegularStyleOrManageMode,
    [COL.SYS_REFUND_LIST]: isNotRegularStyleOrManageMode,
    [COL.SYS_GROUP_BUY_DETAIL]: isNotRegularStyleOrManageMode,
    [COL.SYS_CUT_PRICE_DETAIL]: isNotRegularStyleOrManageMode,
};

export default {
    components: {
        // NavButton,
    },
    data() {
        return {
            headHidden: false, // 顶部样式是否隐藏
        };
    },
    computed: {
        ...mapGetters('app', ['isSysCol']),
        ...mapState('app', ['colId', 'openThemeV3', 'lcid', 'LS', 'colInfo']),
        ...mapState('nav', ['fullPageNav', 'independentNav']),
        ...mapState('header', [
            'headStyle',
            'openLanInfoList',
            'searchOptions',
            'allProductNum',
            'urls',
            'mallOpen',
            'productGroupBtnHref',
            'title',
            'logoJumpInfo',
        ]),
        ...mapGetters('header', ['headStyleId']),
        showHeader() {
            if (!this.isSysCol || showHeaderCols.includes(this.colId)) {
                return true;
            } else if (typeof showHeaderColsByHandler[this.colId] === 'function') {
                return showHeaderColsByHandler[this.colId](this.isRegularStyle);
            }
            return false;
        },
        fullHeadHidden() {
            return this.headStyle.hh || 0;
        },
        independentSetHead() {
            return checkBit(this.colInfo.flag, COL_DEF_FLAG.INDEPEND_SET_HEAD);
        },
        independentSetHeadHide() {
            return checkBit(this.colInfo.flag, COL_DEF_FLAG.INDEPEND_SET_HEAD_HIDE);
        },
        isHiddenHead() {
            return (
                (this.fullHeadHidden == 1 && !this.independentSetHead) ||
                (this.independentSetHead && this.independentSetHeadHide)
            );
        },
        headerClass() {
            let headStyleClass = ` headStyle${this.headStyleId}`;
            let isHomePage = this.colId === COL.SYS_INDEX;
            if (!this.isRegularStyle && !isHomePage) {
                headStyleClass = ' innerHeadStyle';
            }

            if (this.isSysCol && !showHeaderCols.includes(this.colId)) {
                headStyleClass += ' hide';
            }

            if (this.isHiddenHead) {
                headStyleClass += ' J_header_is_hide';
            }

            // 非中文版本
            if ([2052, 1028].indexOf(this.lcid) === -1) headStyleClass += ' isForeignVer';

            return `webHeader_v3${headStyleClass}`;
        },
        isRegularStyle() {
            return this.headStyleId < 5;
        },
        headOpacity() {
            if (this.headStyleId === 3 || this.headStyleId === 4) return { opacity: 0 };
            return {};
        },
        logoJumpUrl() {
            return this.logoJumpInfo.url || void 0;
        },
        logoOnclickStr() {
            return this.logoJumpInfo.onclickStr || void 0;
        },
        titleContent() {
            return this.title.content;
        },
        titleUrl() {
            return this.title.url || void 0;
        },
        titleOnclickStr() {
            return this.title.onclickStr || void 0;
        },
        headTitleClass() {
            return `headTitle_v3 ${!this.titleContent ? 'noneStr' : ''} `;
        },
        hoverTips() {
            return VITE_APP_MODE !== 'visitor' ? '双击修改网站标题' : '';
        },
        showSearchBox() {
            return this.headStyleId < 3 || this.headStyleId === 5;
        },
        hideSearchBox() {
            return this.headStyle?.ts?.hsb ?? 0;
        },
        searchBoxClass() {
            return `${this.showSearchBox ? 'item-flex' : 'hide-element'} searchBox f_input_box ${
                this.headStyleId == 5 && this.hideSearchBox == 1 ? 'hidden' : ''
            }`;
        },
        showDropMenu() {
            return this.searchOptions?.showDropMenu ?? false;
        },
        dropMenuList() {
            return this.headStyle?.dropMenuList || [];
        },
        searchTips() {
            return this.headStyle?.searchTips || '搜索';
        },
        otherTitle() {
            return this.dropMenuList.length > 0 ? this.dropMenuList[0]['value'] : '全站';
        },
        topSearchIconClass() {
            return `f_icon_conter ${
                this.headStyleId == 5 ? 'sb-f_icon_conter' : ''
            } faisco-icons-S000290 g_main_color_v3`;
        },
        lanSwitch() {
            return this.headStyle?.ts?.ls ?? 0;
        },
        iconType() {
            return this.headStyle?.ts?.it ?? 0;
        },
        functionGroupClass() {
            let hideFunGoup = '';
            if (this.lanSwitch === 0 && this.iconType === 4) {
                hideFunGoup = 'hide';
            }
            return `functional_group ${hideFunGoup}`;
        },
        loginClass() {
            return `fun_login icon ${this.iconType === 0 ? 'show' : ''}`;
        },
        profileUrl() {
            return this.urls.profileUrl;
        },
        mcartUrl() {
            return this.urls.mcartUrl;
        },
        mallNavClass() {
            return `head_mallNav icon ${this.iconType === 2 ? 'show' : ''}`;
        },
        showCarV3Class() {
            return `fun_showCarV3 icon ${this.iconType === 1 && this.mallOpen ? 'show' : ''}`;
        },
        isInnerPage() {
            return !this.isRegularStyle && !this.isHomePage;
        },
        headerSearchIconClass() {
            return `head_search icon ${this.iconType === 3 ? 'show' : ''}`;
        },
        showLanSwitch() {
            return this.lanSwitch === 1 ? 'showLanSwitch' : '';
        },
        headLanSwitchIconClass() {
            return `head_lanSwitch icon ${this.showLanSwitch}`;
        },
        col_member_center() {
            return this.LS.col_member_center;
        },
        topLabel() {
            return this.headStyle?.ts?.tl ?? 0;
        },
        prUrl() {
            return this.urls.prUrl;
        },
        mobi_allGoods() {
            return this.LS.mobi_allGoods;
        },
        mlistUrl() {
            return this.urls.mlistUrl;
        },
        member_center_order() {
            return this.LS.member_center_order;
        },
        logoData() {
            return this.headStyle?.lg ?? {};
        },
        titleData() {
            return this.headStyle?.t ?? {};
        },
        manageMode() {
            return VITE_APP_MODE !== 'visitor';
        },
    },
    async mounted() {
        if (VITE_APP_MODE !== 'visitor') {
            // 访客态由顶层注入数据
            await this.loadHeaderInfo();
            await this.$nextTick();
        }
        bindTopSearch(this.searchOptions);

        if (this.headStyleId > 2) {
            setTitleIsEditable('headTitle_v3', true);
        }
        if (this.headStyleId === 3 || this.headStyleId === 4) {
            logoV3StyleAdjust(this.headStyleId, this.logoData, this.titleData);
        }
        if (this.headStyleId === 2 || VITE_APP_MODE !== 'visitor') {
            headBoxFixedV3(true);
        }
        languageCtrlV3();
        if (VITE_APP_MODE !== 'visitor') {
            this.initMultiLanguage();
        }
    },
    methods: {
        ...mapActions('header', ['loadHeaderInfo']),
        createLanUrl(lanCode = '') {
            return lanCode === '' ? '/' : `/${lanCode}/`;
        },
        initMultiLanguage() {
            if (window._openThemeV3) {
                this.initMultiLanguageV3();
                return;
            }
            $(Fai.top.document.getElementById('preview')).hover(
                function () {},
                function () {
                    if ($('#multiLanguageCtrl').length < 1 || $('#multiLanguageCtrl').css('display') === 'none') {
                        return;
                    }
                    setTimeout(function () {
                        LocaterDomService.closeLanguageSelector();
                    }, 100);
                }
            );

            $('.lanItem, .langItem').click(function () {
                if ($(getPreviewObject('lanSelect')).length < 1 || parseInt($(getPreviewObject('lanSelect'))) == 0) {
                    return;
                }
                var topUrl = window.document.location.href;
                var manageUrl = topUrl.split('/')[2];
                var loc = $(this).attr('lanCode');
                var isStaticUrl = loc.indexOf('/h-index.html') != -1;
                if (isStaticUrl) {
                    loc = loc.split('/h-index.html')[0];
                    window.document.location.href = '//' + manageUrl + loc + '/manage/mobiDesign.jsp';
                } else {
                    window.document.location.href = '//' + manageUrl + loc + 'manage/mobiDesign.jsp';
                }
            });
        },
        initMultiLanguageV3() {
            $(window.document.getElementById('preview')).hover(
                function () {},
                function () {
                    if ($('.head_lanSwitch').length < 1 || $('.head_lanSwitch').css('display') === 'none') {
                        return;
                    }
                    setTimeout(function () {
                        var $languageSelect = jm('#languageSelect');
                        $languageSelect.height(0);
                        $languageSelect.css('background', 'rgba(255,255,255,0)').css('overflow', 'hidden');
                    }, 100);
                }
            );

            $('.lanItem, .langItem').click(function () {
                if ($('.head_lanSwitch').length < 1 || $('.head_lanSwitch').css('display') === 'none') {
                    return;
                }
                var topUrl = Fai.top.document.location.href;
                var manageUrl = topUrl.split('/')[2];
                var loc = $(this).attr('lanCode');
                var isStaticUrl = loc.indexOf('/h-index.html') != -1;
                if (isStaticUrl) {
                    loc = loc.split('/h-index.html')[0];
                    Fai.top.document.location.href = '//' + manageUrl + loc + '/manage/mobiDesign.jsp';
                } else {
                    Fai.top.document.location.href = '//' + manageUrl + loc + 'manage/mobiDesign.jsp';
                }
            });
        },
    },
};
</script>

<style lang="scss">
@import './index.scss';
</style>
